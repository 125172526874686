import './App.css';
import SearchBar from './twitch/searchUserComponent';
import StreamerCards from './twitch/StreamerCards';
//import TableauViz from './tableau/TableauViz';

//<script type="module" src="https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"></script>


function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/*
        <code>Search Twitch User</code>
        <SearchBar />
        */}
        <code>Streamers</code>
      </header>
      <div>
        <StreamerCards />
      </div>
      {/*
      <div>
        <TableauViz id='1' url='https://public.tableau.com/views/Top100Streamers-DailyAverageViewers/Sheet2/'></TableauViz>
      </div>
      
      <div>
        <TableauViz id='2' url='https://public.tableau.com/views/TopStreamersLiveViewers/Sheet1'></TableauViz>
      </div>
      */}
      <div>
      </div>
      
    </div>
  );
}

export default App;
