import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './Card.css'


const Card = (props) => {
    const [bgColor] = useState(() => {
        let randomColorIndex = Math.floor(Math.random() * 9);
        let modernColors = ['#0D0B3F', '#C0A2FC', '#D8B4FF', '#A4B0BD', '#CED6E0', '#A981BB', '#F4E2D8', '#C9ADA7', '#A5CDD7'];
        return modernColors[randomColorIndex];
    });

    return (
        <div className="card" style={{borderRadius: "8px"}}>
            { (props.BackgroundPhoto !== "") 
                ? <img className="background" src={props.BackgroundPhoto} alt={`${props.DisplayName} background`} />
                : <div className="background" style={{backgroundColor: bgColor}}></div>
            }
            <img className="profilePhoto" src={props.ProfilePhoto} alt={`${props.DisplayName} profile photo`} />
            <h2 className="displayName">{props.DisplayName}</h2>
            <p className="broadcasterType">{props.BroadcasterType}</p>
        </div>
    );
};


export default Card;