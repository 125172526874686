import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Card from './Card';
import './StreamerCards.css'

const NODE_SERVER = process.env.REACT_APP_NODE_SERVER

const TwitchStreamers = () => {
    const [twitchData, setTwitchData] = useState([]);

    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`${NODE_SERVER}/api/twitchStreamer?limit=10&offset=${pageNumber * 20}`);
            const data = await response.json();
            setTwitchData(data);
        }
        fetchData();
    }, [pageNumber]);

    const handlePageChange = (type) => {
        if (type === 'next') {
            setPageNumber(pageNumber + 1)
        } else {
            setPageNumber(pageNumber - 1)
        }
    }

    return (
        <>
            <div>
                {twitchData.map((streamer) => (
                    <Card
                        key={uuidv4()}
                        DisplayName={streamer.DisplayName}
                        ProfilePhoto={streamer.ProfilePhoto}
                        BackgroundPhoto={streamer.BackgroundPhoto}
                        BroadcasterType={streamer.BroadcasterType}
                    />
                ))}
            </div>
            <div>
                {pageNumber > 0 && <button onClick={() => handlePageChange('previous')}>Previous</button>}
                <button onClick={() => handlePageChange('next')}>Next</button>
            </div>
        </>
    );
};

export default TwitchStreamers;